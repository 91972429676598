.code-input {
  display: flex;
  flex-direction: column;
  align-items: start;
  color: #fff !important;
}

.code-inputs {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
}

.code-inputs input {
  border: none !important;
  border-radius: 0 !important;
  color: #ffff !important;
  background: none !important;
  -webkit-background-image: none !important;
  background-image: none;
  box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  text-align: center !important;
  height: 2.4rem !important;
  width: 3rem !important;
  margin: 0 0.2rem !important;
  padding: 0 !important;
  font-size: 1.4rem !important;
  border-bottom: 1px solid #fff !important;
}

.code-inputs input:focus {
  outline: none !important;
}

@primary-color: rgb(89,54,213);@border-radius-base: 5px;
@import "../../auth/CustomLoginPage/style.less";

.captcha-modal {
  &__col {
    text-align: center;
  }

  &__container {
    display: inline-block;
  }

  &__image {
    background-repeat: no-repeat;
    background-color: white;
    height: 80px;
    width: 200px;
    border-radius: 0.4rem;
    border: 1px solid rgb(255 255 255 / 20%);
    margin-bottom: 20px;
  }

  &__input-row {
    width: 200px;
  }

  &__input {
    height: 50px;
    background-color: #fff !important;
    color: #444 !important;
    border: none !important;
    border-radius: 0.4rem !important;
    padding: 0.5rem 1rem !important;
    width: 100%;
    font-size: 20px !important;

    .anticon {
      color: #444;
    }
  }

  &__content {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  &.ant-modal {
    .ant-modal-content {
      background-color: @bg-color;
      border-radius: 25px;
      padding: 1.5rem;

      .ant-modal-header {
        background-color: transparent;
        border-bottom: none;
        padding: 0;
        margin-bottom: 1.5rem;

        .ant-modal-title {
          color: @text-color;
          font-size: 1.375rem;
          font-weight: bold;
          text-align: center;
        }
      }

      .ant-modal-body {
        padding: 0;
      }

      .ant-modal-footer {
        border-top: none;
        padding: 0;
        margin-top: 1.5rem;
        text-align: center;
        display: flex;

        .ant-btn {
          height: 50px;
          padding: 0 2rem;
          font-size: 16px;
          font-weight: bold;
          border-radius: 0.4rem;
          border: none;

          &.ant-btn-primary {
            background: @btn-color !important;
            color: @text-color;

            &:hover {
              background: @btn-hover !important;
            }

            &:disabled {
              background: #9b9b9b !important;
              cursor: not-allowed;
              opacity: 1;
              color: #fff;
            }
          }

          &.ant-btn-default {
            background: transparent;
            color: @text-color;
            border: 1px solid rgb(255 255 255 / 20%);

            &:hover {
              border-color: rgb(255 255 255 / 40%);
            }
          }
        }
      }
    }
  }
}

@primary-color: rgb(89,54,213);@border-radius-base: 5px;
@text-color: #FFF;

.pattern-input {
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  border-bottom: 1px solid @text-color;
  border-radius: 0;
  color: @text-color;
  font-size: 1rem;

  &::placeholder {
    color: rgb(255 255 255 / 50%);
  }

  &:focus {
    box-shadow: none;
    border-color: @text-color;
  }

  input {
    background: transparent;
    color: @text-color;

    &::placeholder {
      color: rgb(255 255 255 / 50%);
    }
  }

  &__input {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    font-size: 16px;
  }

  &__input:focus-visible {
    outline: none;
  }
}

@primary-color: rgb(89,54,213);@border-radius-base: 5px;